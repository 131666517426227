import React, { useState, useCallback } from "react";
import { css, keyframes } from "@emotion/core";
//import BackgroundImage from "gatsby-background-image";
import { graphql, useStaticQuery } from "gatsby";
import { useSwipeable } from "react-swipeable";

import { useInterval } from "../hooks/use-interval";

import Hero from "../components/hero";
import { GreenBreak, GalleryArrow } from "../components/styled-components";

const fadeTransition = keyframes`
  0% {
    opacity: 0
  }
  50% {
    opacity: .25
  }
  100% {
    opacity: 1
  }
`;

const animatedCss = css`
  width: 80%;
  height: 55vh;
  max-width: 1000px;
  animation: ${fadeTransition} 0.5s linear 1;
  @media (max-width: 768px) {
    width: 90vw;
    height: fit-content;
  }
`;

const regularCss = css`
  width: 80%;
  height: 55vh;
  max-width: 1000px;
  @media (max-width: 768px) {
    width: 90vw;
    height: fit-content;
  }
`;

// const Thumbnail = React.memo(
//   ({ image, index, handleClick, currentImage }) => (
//     <BackgroundImage
//       key={image.slug}
//       fadeIn={false}
//       loading="eager"
//       fluid={image.galleryImage.fluid}
//       alt={image.alt}
//       style={{
//         overflow: "hidden",
//         minWidth: "50px",
//         minHeight: "50px",
//         border:
//           currentImage.slug === image.slug
//             ? "2px solid white"
//             : "2px solid #f1a948",
//       }}
//       css={css`
//         background-size: cover;
//         width: 70px;
//         height: 70px;
//         min-width: 70px !important;
//         min-height: 70px;

//         border-radius: 50%;
//         position: relative;
//         overflow: hidden;
//         transition: all 0.25s ease;
//         margin: 0 0.5rem 0 0;
//         &:last-of-type {
//           margin-right: 0;
//         }
//         filter: grayscale(60%);
//         :hover {
//           cursor: pointer;
//           filter: grayscale(0%);
//           border: 2px solid white;
//         }
//       `}
//       onClick={() => {
//         handleClick(index);
//       }}
//     />
//   ),
//   (prev, next) => prev.currentImage.slug === next.currentImage.slug
// );

const HeroWrapper = React.memo(
  ({ currentImage }) => {
    return (
      <div
        css={css`
          width: 100%;
          height: fit-content;
        `}
      >
        <Hero
          fluid={currentImage.galleryImage.fluid}
          alt={currentImage.alt}
          type={"WtoO"}
        />
      </div>
    );
  },
  (prev, next) => {
    return prev.currentImage.slug === next.currentImage.slug;
  }
);

const ImageGallery = () => {
  const galleryImages = useStaticQuery(graphql`
    query {
      allContentfulGalleryImage(filter: { tags: { in: "gallery" } }) {
        nodes {
          alt
          title
          slug
          childContentfulGalleryImageDescriptionTextNode {
            description
          }
          galleryImage {
            fluid(quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `);

  const [currentImage, setCurrentImage] = useState(
    galleryImages.allContentfulGalleryImage.nodes[0]
  );
  const [currentIndex, setCurrentIndex] = useCallback(useState(0));
  const [animated, setAnimated] = useCallback(useState(false));
  const [delay, setDelay] = useCallback(useState(10000));

  const handleClick = useCallback(
    (index, source) => {
      setAnimated(true);
      setCurrentImage(galleryImages.allContentfulGalleryImage.nodes[index]);
      setCurrentIndex(index);
      if (source === "button") {
        setDelay(null);
      }
    },
    [
      galleryImages.allContentfulGalleryImage.nodes,
      setAnimated,
      setCurrentIndex,
      setDelay,
    ]
  );

  const swipeHandler = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === "Left") {
        let index =
          currentIndex ===
          galleryImages.allContentfulGalleryImage.nodes.length - 1
            ? 0
            : currentIndex + 1;

        handleClick(index, "button");
      } else if (eventData.dir === "Right") {
        let index =
          currentIndex === 0
            ? galleryImages.allContentfulGalleryImage.nodes.length - 1
            : currentIndex - 1;

        handleClick(index, "button");
      }
    },
  });

  useInterval(() => {
    handleClick(
      currentIndex === galleryImages.allContentfulGalleryImage.nodes.length - 1
        ? 0
        : currentIndex + 1
    );
    setCurrentIndex(
      currentIndex === galleryImages.allContentfulGalleryImage.nodes.length - 1
        ? 0
        : currentIndex + 1
    );
  }, delay);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      `}
    >
      <div
        css={css`
          width: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
        `}
      >
        <GalleryArrow
          direction="left"
          onClick={() => {
            let index =
              currentIndex === 0
                ? galleryImages.allContentfulGalleryImage.nodes.length - 1
                : currentIndex - 1;

            handleClick(index, "button");
          }}
        />
        <div
          css={animated ? animatedCss : regularCss}
          onAnimationEnd={() => setAnimated(false)}
          {...swipeHandler}
        >
          <HeroWrapper currentImage={currentImage} />
        </div>
        <GalleryArrow
          direction="right"
          onClick={() => {
            let index =
              currentIndex ===
              galleryImages.allContentfulGalleryImage.nodes.length - 1
                ? 0
                : currentIndex + 1;

            handleClick(index, "button");
          }}
        />
      </div>
      <br />
      <br />
      <GreenBreak height="12vh" minHeight="150px">
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            text-align: center;
            overflow: hidden;
            @media (max-width: 768px) {
              width: 90vw;
            }
          `}
        >
          {/* <div
            className="invisible-scroll"
            css={css`
              display: flex;
              width: fit-content;
              height: 70px;
              max-width: 750px;
              overflow: auto;
              margin: 1rem auto 1rem auto;
              align-items: center;
              @media (max-width: 768px) {
                max-width: 90vw;
              }
            `}
          >
            {galleryImages.allContentfulGalleryImage.nodes.map(
              (image, index) => {
                return (
                  <Thumbnail
                    key={index}
                    image={image}
                    index={index}
                    handleClick={() => handleClick(index, "button")}
                    currentImage={currentImage}
                  />
                );
              }
            )}
          </div> */}
          <h2
            css={css`
              color: white;
              @media (max-width: 1280px) {
                font-size: 1.5rem;
              }
              @media (max-width: 950px) {
                font-size: 1.25rem;
              }
              @media (max-width: 768px) {
                font-size: 1rem;
              }
            `}
          >
            {currentImage.title}
          </h2>
          <h2
            css={css`
              color: white;
              @media (max-width: 1280px) {
                font-size: 1.5rem;
              }
              @media (max-width: 950px) {
                font-size: 1.25rem;
              }
              @media (max-width: 768px) {
                font-size: 1rem;
              }
            `}
          >
            {
              currentImage.childContentfulGalleryImageDescriptionTextNode
                .description
            }
          </h2>
        </div>
      </GreenBreak>
    </div>
  );
};

export default React.memo(ImageGallery);
